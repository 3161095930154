import React, { FC } from 'react';

import {
    Button, ModalBody,
    ModalFooter, ModalHeader,
} from 'reactstrap';

import { translate } from '../../../helpers/translations/translator';
import { NewlineToBreak } from '../../../@paco/components';

interface Props {
    title: string;
    intro: string;
    onCancel: () => void;
    onSubmit: () => void;
}

const DeleteResourceForm:FC<Props> = ({
    title,
    intro,
    onCancel,
    onSubmit,
}) => (
    <div className="form-track form-delete-track">
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
            <p className="registration-intro">
                <NewlineToBreak text={intro} />
            </p>
        </ModalBody>
        <ModalFooter>
            <Button
                type="button"
                color="link"
                id="modal-close"
                onClick={onCancel}
            >
                {translate('common.cancel')}
            </Button>
            <Button type="submit" color="orange" onClick={onSubmit}>{translate('common.delete')}</Button>
        </ModalFooter>
    </div>
);

export default DeleteResourceForm;
