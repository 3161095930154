import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl, generateApiV3Url } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { NotificationCenterHeaderName, NotificationCenterLog } from '../NotificationCenter/NotificationCenterLog';
import {
    ContractHours,
    ContractHoursFormData,
    ContractHoursResource,
    EditContractHoursFormData,
    UserContractHours,
    UserContractHoursV3Resource,
} from './ContractHours';
import {
    transformToAddContractHoursRequest,
    transformToContractHours,
    transformToEditContractHoursRequest,
    transformToUserContractHours,
} from './ContractHoursTransformers';

export const getUsersContractHoursApiCall = async (): Promise<FetchCollectionResult<UserContractHours[], string>> => {
    try {
        const url = generateApiV3Url({ endpoint: '/users/periodical-contract-hours' });

        const response = await authorizedFetch(url);

        const doc: UserContractHoursV3Resource[] = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const contractHours = doc.map(transformToUserContractHours);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: contractHours,
        };
    } catch (error) {
        console.error('[getUsersContractHoursApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const addContractHourApiCall = async (formData: ContractHoursFormData, userId: string, eventLogTrackerData?: NotificationCenterLog): Promise<FetchResult<ContractHours, string>> => {
    const apiData = transformToAddContractHoursRequest(formData, userId);

    try {
        const url = generateApiUrl({ endpoint: '/periodical-contract-hours' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ContractHoursResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const contractHoursResource = doc.data as ContractHoursResource;
        const contractHours = transformToContractHours(contractHoursResource);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: contractHours,
        };
    } catch (error) {
        console.error('[addContractHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const editContractHourApiCall = async (formData: EditContractHoursFormData, eventLogTrackerData?: NotificationCenterLog): Promise<FetchResult<ContractHours, string>> => {
    const apiData = transformToEditContractHoursRequest(formData);

    try {
        const url = generateApiUrl({ endpoint: `/periodical-contract-hours/${formData.resourceId}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ContractHoursResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const contractHoursResource = doc.data as ContractHoursResource;
        const contractHours = transformToContractHours(contractHoursResource);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: contractHours,
        };
    } catch (error) {
        console.error('[editContractHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteContractHourApiCall = async (formData: EditContractHoursFormData, eventLogTrackerData?: NotificationCenterLog): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/periodical-contract-hours/${formData.resourceId}` });

        const response = await authorizedFetch(url, {
            method: 'DELETE',
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteContractHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

