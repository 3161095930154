import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import EmptyList from '../../../components/EmptyList/EmptyList';
import { LoadingSpinner } from '../../components';
import { EventLog } from '../../entities/EventLog/EventLog';
import trans from '../../helpers/trans';
import { ModalContent, PacoModal } from '../index';
import PacoDetailLogs from '../PacoDetailLogs/PacoDetailLogs';
import { generateLogsDateObject, logsDateObjectProps } from './helpers';

import './PacoLogsModal.scss';

export interface PacoLogsModalProps {
    loading?: boolean;
    isOpen: boolean;
    onClose: () => void;
    logs: EventLog[];
    title: string;
}

const PacoLogsModal: FC<PacoLogsModalProps> = ({
    loading = false,
    isOpen,
    onClose,
    logs,
    title,
}): ReactElement => {
    const [logsDateObject, setLogsDateObject] = useState<logsDateObjectProps>({});

    useEffect(() => {
        if (logs) {
            setLogsDateObject(generateLogsDateObject(logs));
        }
    }, [logs]);

    return (
        <div>
            {isOpen && (
                <PacoModal
                    isWide
                    className="paco-logs-modal"
                >
                    {logs.length > 0 && (
                        <ModalContent
                            title={title}
                            showCloseButton
                            onCloseButtonClick={onClose}
                        >
                            {Object.values(logsDateObject).map((logsInDate) => (
                                <PacoDetailLogs
                                    key={logsInDate[0].id}
                                    firstLogActive={logsInDate[0].id === logs[0].id}
                                    detailLogs={logsInDate}
                                    className="paco-logs-modal__detail-logs"
                                />
                            ))}
                        </ModalContent>
                    )}

                    {!loading && logs.length === 0 && (
                        <ModalContent
                            title={title}
                            showCloseButton
                            onCloseButtonClick={onClose}
                            className="paco-logs-modal__zero-modal-content"
                        >
                            <EmptyList
                                text={trans('containers.userDetail.emptyUserLog')}
                                className="paco-logs-modal__zero-logs"
                            />
                        </ModalContent>
                    )}
                </PacoModal>
            )}
            {loading && <LoadingSpinner />}
        </div>
    );
};

export default PacoLogsModal;
