import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Absence } from '../../entities/Absence/Absence';
import { ChangeLogEntry } from '../../entities/ChangeLog/ChangeLog';
import { DashboardSetting } from '../../entities/DashboardSetting/DashboardSetting';
import { Department } from '../../entities/Department/Department';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { Track } from '../../entities/Track/Track';

enum LocalStorageItemKey {
    selectedDepartments = 'selectedDashboardDepartments',
    lastChangeLogEntryId = 'lastChangeLogEntryId',
}

export type DashboardState = AsyncReduxState<{
    hasUpdatedChangeLog: boolean;
    hasViewedChangeLog: boolean;
    isAbsencesLoading: boolean;
    isChangeLogLoading: boolean;
    isShiftsLoading: boolean;
    isTracksLoading: boolean;
    absences: Absence[];
    changeLog: ChangeLogEntry[];
    lastChangeLogEntryId?: string;
    selectedDepartments: Department[];
    settings: DashboardSetting[];
    shifts: ShiftIndex[];
    tracks: Track[];
}>;

const localStorageSelectedDepartments = localStorage.getItem(LocalStorageItemKey.selectedDepartments);
const localStorageLastChangeLogEntryId = localStorage.getItem(LocalStorageItemKey.lastChangeLogEntryId) || undefined;
const selectedDepartments = localStorageSelectedDepartments ? JSON.parse(localStorageSelectedDepartments) : [];

const initialState: DashboardState = {
    ...initialAsyncReduxState,
    hasUpdatedChangeLog: false,
    hasViewedChangeLog: false,
    isAbsencesLoading: false,
    isChangeLogLoading: false,
    isShiftsLoading: false,
    isTracksLoading: false,
    absences: [],
    changeLog: [],
    lastChangeLogEntryId: localStorageLastChangeLogEntryId,
    selectedDepartments,
    settings: [],
    shifts: [],
    tracks: [],
};

export const DashboardSlice = createSlice({
    name: 'DashboardReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsAbsencesLoading(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                isAbsencesLoading: action.payload,
            };
        },
        setIsChangeLogLoading(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                isChangeLogLoading: action.payload,
            };
        },
        setIsShiftsLoading(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                isShiftsLoading: action.payload,
            };
        },
        setIsTracksLoading(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                isTracksLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DashboardState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAbsences(state, action: PayloadAction<Absence[]>): DashboardState {
            return {
                ...state,
                absences: action.payload,
            };
        },
        setChangeLog(state, action: PayloadAction<ChangeLogEntry[]>): DashboardState {
            const lastChangeLogEntryId = action.payload[0]?.id;
            if (lastChangeLogEntryId) {
                localStorage.setItem(LocalStorageItemKey.lastChangeLogEntryId, lastChangeLogEntryId);
            }

            return {
                ...state,
                lastChangeLogEntryId,
                changeLog: action.payload,
                hasUpdatedChangeLog: state.lastChangeLogEntryId !== lastChangeLogEntryId,
            };
        },
        setHasViewedChangeLog(state, action: PayloadAction<boolean>): DashboardState {
            return {
                ...state,
                hasViewedChangeLog: action.payload,
            };
        },
        setDashboardSettings(state, action: PayloadAction<DashboardSetting[]>): DashboardState {
            return {
                ...state,
                settings: action.payload,
            };
        },
        setShifts(state, action: PayloadAction<ShiftIndex[]>): DashboardState {
            return {
                ...state,
                shifts: action.payload,
            };
        },
        setTracks(state, action: PayloadAction<Track[]>): DashboardState {
            return {
                ...state,
                tracks: action.payload,
            };
        },
        setSelectedDepartments(state, action: PayloadAction<Department[]>): DashboardState {
            localStorage.setItem(LocalStorageItemKey.selectedDepartments, JSON.stringify(action.payload));

            return {
                ...state,
                selectedDepartments: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsAbsencesLoading,
    setIsChangeLogLoading,
    setIsShiftsLoading,
    setIsTracksLoading,
    setAbsences,
    setChangeLog,
    setHasViewedChangeLog,
    setDashboardSettings,
    setShifts,
    setTracks,
    setSelectedDepartments,
    setError,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
