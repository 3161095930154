import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    NotificationCenterAction,
    NotificationCenterActionCategory,
    NotificationCenterCategory,
    NotificationCenterHeaderName,
    NotificationCenterLog,
} from './NotificationCenterLog';
import { notificationCenterTransformer } from './NotificationCenterTransformer';

export const startEventLoggerApiCall = async (
    category: NotificationCenterCategory,
    action: NotificationCenterAction,
    tags: NotificationCenterActionCategory[],
): Promise<FetchResult<NotificationCenterLog, string>> => {
    try {
        const url = generateApiV3Url({ endpoint: '/event-logs/start' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({
                category,
                action,
                tags,
            }),
        });

        const doc = await response.json();

        const data = notificationCenterTransformer(doc);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[startEventLoggerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const endEventLoggerApiCall = async (eventLogTrackerData: NotificationCenterLog): Promise<FetchResult<string, string>> => {
    try {
        const url = generateApiV3Url({ endpoint: '/event-logs/end' });

        const response = await authorizedFetch(url, {
            method: 'GET',
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: 'Event ended successfully',
        };
    } catch (error) {
        console.error('[endEventLoggerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
