import {
    FC,
    ReactElement,
    useState,
} from 'react';

import classnames from 'classnames';

import {
    EmptyState,
    ModalContent,
    PacoModal,
    PacoPagination,
    PzLogDetail,
} from '../../compositions';
import { EventLog } from '../../entities/EventLog/EventLog';
import { EventLogCategory } from '../../entities/EventLogCategory/EventLogCategory';
import trans from '../../helpers/trans';
import PzLogsTable from '../@tables/PzLogsTable/PzLogsTable';
import { PzLogsTopBar } from './subcomponents';

import './PzLogs.scss';

interface PzLogsProps {
    isLoading: boolean;
    isPaginationLoading: boolean;
    categories: EventLogCategory[];
    logs: EventLog[];
    page: number;
    totalPages: number;
    onPaginationChange: (page: number) => void;
    className?: string;
}

const PzLogs: FC<PzLogsProps> = ({
    isLoading,
    isPaginationLoading,
    categories,
    logs,
    page,
    totalPages,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const [activeLog, setActiveLog] = useState<EventLog>();

    // const activeLogCategory = useMemo(() => categories.find(category => category.slug === activeLog?.categorySlug), [categories, activeLog]);

    const paginationClassNames = classnames('pz-logs__pagination', {
        'pz-logs__pagination--is-loading': isPaginationLoading,
    });

    const handleCloseModalClick = (): void => {
        setActiveLog(undefined);
    };

    if (!logs.length && !isLoading) {
        return (
            <main className={`pz-logs ${className}`}>
                <PzLogsTopBar className="pz-logs__top-bar" />
                <EmptyState
                    title={trans('containers.pzLogs.pzLogsEmptyStateTitle')}
                    intro={trans('containers.pzLogs.pzLogsEmptyStateIntro')}
                    className="pz-logs__empty-state"
                />
            </main>
        );
    }

    return (
        <main className={`pz-logs ${className}`}>
            <PzLogsTopBar className="pz-logs__top-bar" />
            <PzLogsTable
                isLoading={isLoading}
                categories={categories}
                logs={logs}
                onViewLogClick={setActiveLog}
                className="pz-logs__table"
            />
            {(totalPages > 0 && logs.length > 0) && (
                <PacoPagination
                    id="pz-logs-pagination"
                    amountOfPages={totalPages}
                    currentPage={page}
                    onChange={onPaginationChange}
                    className={paginationClassNames}
                />
            )}
            {activeLog && (
                <PacoModal>
                    <ModalContent title={trans('compositions.pzLogDetail.title')}>
                        <PzLogDetail
                            log={activeLog}
                            onClose={handleCloseModalClick}
                        />
                    </ModalContent>
                </PacoModal>
            )}
        </main>
    );
};

export default PzLogs;
