import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { FormOption } from '../../types';
import { EventLogActionType } from '../../types/eventLogTypes';
import { EventLogCategory } from '../EventLogCategory/EventLogCategory';
import {
    EventLog,
    EventLogDataItem,
    EventLogDataItemBodyResource,
    EventLogEntityType,
    EventLogResource,
} from './EventLog';

export const transformToEventLogDataItem = (
    bodyData: EventLogDataItemBodyResource,
    entityUuid: string,
    entityType: EventLogEntityType,
): EventLogDataItem => {
    const eventLogData = Object.fromEntries(
        Object.entries(bodyData).filter(([key]) => !['_summary', '_entity'].includes(key)),
    );

    return {
        eventLogData,
        title: '',
        action: EventLogActionType.updated,
        category: '',
        subTitle: '',
        entityType,
        entityUuid,
    };
};

export const transformToEventLog = (resource: EventLogResource): EventLog => {
    const bodyData = resource.data.body;
    const { entityUuid } = resource.data;

    const entityTypeKey = getEnumKeyByEnumValue(EventLogEntityType, resource.data.entityType);
    const actionTypeKey = getEnumKeyByEnumValue(EventLogActionType, resource.data.action);

    if (!entityTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.data.entityType}".`);
    }

    if (!actionTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.data.action}".`);
    }

    const logDataItem = transformToEventLogDataItem(
        bodyData,
        entityUuid,
        entityTypeKey ? EventLogEntityType[entityTypeKey] : EventLogEntityType.other,
    );

    return {
        id: resource.uuid,
        name: resource.data.body._entity ? resource.data.body._entity.fullName : '',
        personUuid: resource.data.body._entity ? resource.data.body._entity.uuid : '',
        summary: resource.data.body._summary,
        action: actionTypeKey ? EventLogActionType[actionTypeKey] : EventLogActionType.created,
        categorySlug: resource.data.category,
        createdAt: new Date(resource.createdAt),
        createdBy: resource.createdBy,
        eventLog: [logDataItem],
        entityType: entityTypeKey ? EventLogEntityType[entityTypeKey] : EventLogEntityType.other,
        entityUuid,
        message: resource.data.title,
        updatedAt: new Date(resource.updatedAt),
    };
};

export const transformEventLogCategoryToFormOption = (eventLogCategory: EventLogCategory): FormOption => ({
    label: eventLogCategory.name,
    value: eventLogCategory.slug,
});
