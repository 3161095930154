import { patchEmploymentApiCall } from '../../entities/Employment/EmploymentService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { NotificationCenterLog } from '../../entities/NotificationCenter/NotificationCenterLog';
import { EmploymentDatesFormData } from '../../entities/User/User';

export const editEmployment = async (employmentId: string, formData: EmploymentDatesFormData, eventLogTrackerData?: NotificationCenterLog): Promise<void> => {
    const response = await patchEmploymentApiCall(employmentId, formData, eventLogTrackerData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editEmployment');
    }
};
