import React, { FC, useEffect, useState } from 'react';

import packageJson from '../../../../package.json';
import { ChangeLogEntry, ChangeLogPopupMessage } from '../../entities/ChangeLog/ChangeLog';
import trans from '../../helpers/trans';
import { AppUpdatesWidgetItem } from './subcomponents';
import { ChangeLogWidgetPopupMessage } from './subcomponents/ChangeLogWidgetPopupMessage/ChangeLogWidgetPopupMessage';
import ChangeLogWidgetSkeleton from './subcomponents/ChangeLogWidgetSkeleton/ChangeLogWidgetSkeleton';

import './ChangeLogWidget.scss';

interface ChangeLogWidgetProps {
    hasUpdatedChangeLog: boolean,
    isLoading: boolean;
    changeLog: ChangeLogEntry[];
    hasViewedChangeLog?: boolean;
    onViewChangeLog?: () => void;
    className?: string;
}

const ChangeLogWidget: FC<ChangeLogWidgetProps> = ({
    hasUpdatedChangeLog,
    isLoading,
    changeLog,
    hasViewedChangeLog,
    onViewChangeLog,
    className = '',
}) => {
    const [activeItem, setActiveItem] = useState<string | undefined>();
    const [popupMessage, setPopupMessage] = useState<ChangeLogPopupMessage | undefined>();

    const handleItemButtonClick = (id: string) => {
        setActiveItem(activeItem === id ? undefined : id);
    };

    useEffect(() => {
        if (hasUpdatedChangeLog) {
            setActiveItem(changeLog[0]?.id);
        }
    }, [hasUpdatedChangeLog]);

    useEffect(() => {
        if (!activeItem) {
            return;
        }

        const itemContents = changeLog.find(({ id }) => id === activeItem);
        if (itemContents?.popupMessage && !hasViewedChangeLog) {
            setPopupMessage(itemContents.popupMessage);
            onViewChangeLog?.();
        }
    }, [activeItem, hasViewedChangeLog]);

    if (isLoading) {
        return (
            <ChangeLogWidgetSkeleton className={className} />
        );
    }

    return (
        <div className={`change-log-widget ${className}`}>
            <h2 className="change-log-widget__title">
                {trans('compositions.dashboardChangeLogWidget.title')}
            </h2>
            <div className="change-log-widget__container">
                <h3 className="change-log-widget__sub-title">
                    {trans('compositions.dashboardChangeLogWidget.currentAppVersion')}
                </h3>
                {`v${packageJson.version}`}

                <div className="change-log-widget__list">
                    <h3 className="change-log-widget__sub-title">
                        {trans('compositions.dashboardChangeLogWidget.recentUpdates')}
                    </h3>
                    {changeLog.map(entry => (
                        <AppUpdatesWidgetItem
                            key={entry.id}
                            isOpen={activeItem === entry.id}
                            changeLogEntry={entry}
                            onButtonClick={handleItemButtonClick}
                            className="change-log-widget__list-item"
                        />
                    ))}
                </div>
            </div>
            {popupMessage && (
                <ChangeLogWidgetPopupMessage
                    popupMessage={popupMessage}
                    onDismiss={() => setPopupMessage(undefined)}
                />
            )}
        </div>
    );
};

export default ChangeLogWidget;
