import { FC } from 'react';

export interface NewlineToBreakProps {
    text: string;
}

export const NewlineToBreak: FC<NewlineToBreakProps> = ({ text }) => (
    <>
        {text.split('\r\n').map((line, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={key}>
                {line}
                <br />
            </span>
        ))}
    </>
);
