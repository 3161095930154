import { FC, ReactElement } from 'react';

import './PzLogListItem.scss';

interface PzLogListItemProps {
    label: string;
    value: string;
    className?: string;
}

const PzLogListItem: FC<PzLogListItemProps> = ({
    label,
    value,
    className = '',
}): ReactElement => (
    <li className={`pz-log-list-item ${className}`}>
        <div className="pz-log-list-item__label">
            {label}
        </div>
        <div className="pz-log-list-item__value">
            {value}
        </div>
    </li>
);

export default PzLogListItem;
