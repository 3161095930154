import { Permission } from '../../@paco/entities/Permission/Permission';
import { Role } from '../../@paco/entities/Role/Role';
import { ResourceType } from '../../@paco/types/resourceTypes';
import { USER_ROLE_EMPLOYEE, USER_ROLE_JUNIOR_MANAGER } from '../../constants';
import { checkPermission } from '../index';
import { hasCurrentUserRoleToEditEntity } from './hasCurrentRoleToEditEntity';

const getPermission = (
    currentUserRole: Role | undefined,
    entityRoles: Role[] = [],
    permissions: Permission[],
    type: ResourceType,
    action: 'edit' | 'add-new' | 'delete',
): boolean => {
    if (!currentUserRole) {
        return false;
    }

    const allowedRoles = ['establishment-manager', 'admin', 'human-resources', 'head-manager'];
    const hasCurrentUserCorrectRole = allowedRoles.includes(currentUserRole.slug);

    if (hasCurrentUserCorrectRole) {
        return hasCurrentUserRoleToEditEntity(currentUserRole, entityRoles);
    }

    const isAllowedAll = checkPermission(permissions, `${action}-all-${type}`) || checkPermission(permissions, `add-new-${type}`);

    if (isAllowedAll) {
        return true;
    }

    const isAllowedEmployees = checkPermission(permissions, `${action}-employee-${type}`);
    const isAllowedJuniorManagers = checkPermission(permissions, `${action}-junior-manager-${type}`);

    const userRoleIsEmployee = entityRoles.some(role => role.slug === USER_ROLE_EMPLOYEE);
    const userRoleIsJunMa = entityRoles.some(role => role.slug === USER_ROLE_JUNIOR_MANAGER);

    return (isAllowedEmployees && userRoleIsEmployee) || (isAllowedJuniorManagers && userRoleIsJunMa);
};

const getPermissionToRemoveLeaveOfAbsence = (
    currentUserRole: Role | undefined,
    permissions: Permission[],
): boolean => {
    const deleteLeaveOfAbsenceAllowedRoles = ['admin', 'establishment-manager', 'human-resources'];

    if (!currentUserRole || !deleteLeaveOfAbsenceAllowedRoles.includes(currentUserRole.slug)) {
        return false;
    }

    return checkPermission(permissions, 'delete-all-leave-of-absences');
};

export const getPermissionToEdit = (
    currentUserRole: Role | undefined,
    entityRoles: Role[] = [],
    permissions: Permission[],
    type: ResourceType,
): boolean => getPermission(currentUserRole, entityRoles, permissions, type, 'edit');

export const getPermissionToAdd = (
    currentUserRole: Role | undefined,
    entityRoles: Role[] = [],
    permissions: Permission[],
    type: ResourceType,
): boolean => getPermission(currentUserRole, entityRoles, permissions, type, 'add-new');

export const getPermissionToDelete = (
    currentUserRole: Role | undefined,
    entityRoles: Role[] = [],
    permissions: Permission[],
    type: ResourceType,
): boolean => getPermission(currentUserRole, entityRoles, permissions, type, 'delete');

export const getPermissionDeleteLeaveOfAbsence = (
    currentUserRole: Role | undefined,
    permissions: Permission[],
): boolean => getPermissionToRemoveLeaveOfAbsence(currentUserRole, permissions);
