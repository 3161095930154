import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { NotificationCenterHeaderName, NotificationCenterLog } from '../NotificationCenter/NotificationCenterLog';
import { DivergentPreEmploymentHour, DivergentPreEmploymentHourFormData, DivergentPreEmploymentHourResource } from './DivergentPreEmploymentHour';
import {
    transformDivergentPreEmploymentHourFormDataToPatchRequest,
    transformDivergentPreEmploymentHourFormDataToPostRequest,
    transformToDivergentPreEmploymentHour,
} from './DivergentPreEmploymentHourTransformers';

export const patchDivergentPreEmploymentHourApiCall = async (
    divergentPreEmploymentHourId: string,
    formData: DivergentPreEmploymentHourFormData,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<DivergentPreEmploymentHour, string>> => {
    const apiData = transformDivergentPreEmploymentHourFormDataToPatchRequest(divergentPreEmploymentHourId, formData);

    try {
        const url = generateApiUrl({
            endpoint: `/divergent-pre-employment-hours/${divergentPreEmploymentHourId}`,
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DivergentPreEmploymentHourResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const employmentData = transformToDivergentPreEmploymentHour(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: employmentData,
        };
    } catch (error) {
        console.error('[patchDivergentPreEmploymentHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDivergentPreEmploymentHourApiCall = async (
    employmentId: string,
    formData: DivergentPreEmploymentHourFormData,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<DivergentPreEmploymentHour, string>> => {
    const apiData = transformDivergentPreEmploymentHourFormDataToPostRequest(employmentId, formData);

    try {
        const url = generateApiUrl({
            endpoint: '/divergent-pre-employment-hours',
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DivergentPreEmploymentHourResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const employmentData = transformToDivergentPreEmploymentHour(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: employmentData,
        };
    } catch (error) {
        console.error('[postDivergentPreEmploymentHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
