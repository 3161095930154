export interface NotificationCenterResource {
    uuid: string;
    action: string;
    category: string;
    tags: string[];
    currentUserName: string;
}

export interface NotificationCenterLog {
    uuid: string;
    action: string;
    category: string;
    tags: string[];
    currentUserName: string;
}

// Update this enum from /api/v3/event-logs/categories if needed
export enum NotificationCenterCategory {
    ABSENCE = 'verzuim',
    AGNOSTIC = 'agnostic',
    CUSTOM = 'custom',
    DATA_UPDATE = 'data-update',
    EMAIL = 'email',
    ENTITY = 'entity',
    LEAVE_OF_ABSENCE = 'verlof',
    SHIFT = 'dienst',
    TRACKS = 'tracks',
    UNKNOWN = 'unknown',
    USER = 'gebruiker',
}

// Update this enum from /api/v3/event-logs/actions if needed
export enum NotificationCenterAction {
    CREATED = 'created',
    DELETED = 'deleted',
    GENERATE = 'generate',
    NOTIFICATION = 'notification',
    UNKNOWN = 'unknown',
    UPDATED = 'updated',
}

// Update this enum from /api/v3/event-logs/tags if needed
export enum NotificationCenterActionCategory {
    CONCEPT_SERVICE_CREATED = 'concept-dienst-aangemaakt',
    SERVICE_CREATED = 'dienst-aangemaakt',
    SERVICE_DELETED = 'dienst-verwijderd',
    SERVICE_PLANNING_CREATED = 'dienstplanning-aangemaakt',
    SERVICE_PLANNING_UPDATED = 'dienstplanning-gewijzigd',
    SERVICE_UPDATED = 'dienst-gewijzigd',
    UNKNOWN = 'unknown',
}

export enum NotificationCenterHeaderName {
    X_EVENT = 'x-event',
}

