import React, { FC } from 'react';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { NewlineToBreak } from '../../../../components';
import Button from '../../../../components/@buttons/Button/Button';
import { ChangeLogPopupMessage } from '../../../../entities/ChangeLog/ChangeLog';
import trans from '../../../../helpers/trans';

export interface ChangeLogWidgetPopupMessageProps {
    popupMessage: ChangeLogPopupMessage;
    onDismiss: () => void;
}

export const ChangeLogWidgetPopupMessage: FC<ChangeLogWidgetPopupMessageProps> = ({
    popupMessage,
    onDismiss,
}) => (
    <Modal
        size="lg"
        isOpen
        className="change-log-widget__popup-message"
    >
        <ModalBody className="popup-message__text">
            <NewlineToBreak text={popupMessage.text} />
        </ModalBody>
        <ModalFooter>
            <Button
                type="button"
                color="orange"
                text={trans('common.close')}
                className="btn btn-orange"
                onClick={onDismiss}
            />
        </ModalFooter>
    </Modal>
);
