import { getEventLogsByEntityApiCall, getEventLogsByEntityNewApiCall } from '../../entities/EventLog/EventLogService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { EventLogEntityType } from '../../types/eventLogTypes';
import { TypedDispatch } from '../store';
import {
    setError,
    setEventLogs,
    setEventLogsByEntity,
    setIsLoading,
} from './eventLogsReducer';

export const getEventLogsForEntity = (entityId: string, entityType?: EventLogEntityType) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getEventLogsByEntityApiCall(entityId, entityType);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setEventLogs(response.data));
    } catch (error) {
        console.error('[getEventLogsForEntity]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getEventsLogsByEntity = (uuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getEventLogsByEntityNewApiCall(uuid);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setEventLogsByEntity(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.error('[getEventsLogsByEntity]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
