import { FC, ReactElement } from 'react';

import FilterBlock from '../../../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import { CheckboxList } from '../../../compositions';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

interface PzLogCategoriesFilterContainerProps {
    categoryOptions: FormOption[];
    onChange: (selectedCategoryOptions: FormOption[]) => void;
    className?: string;
}

const PzLogCategoriesFilterContainer: FC<PzLogCategoriesFilterContainerProps> = ({ categoryOptions, onChange, className = '' }): ReactElement => {
    const selectedCategoryOptions = categoryOptions.filter(categoryOption => categoryOption.isChecked);

    const handleOptionsChange = (checkedOptionValues: string[]): void => {
        const changedOptions = categoryOptions.map(categoryOption => ({
            ...categoryOption,
            isChecked: checkedOptionValues.some(checkedOptionValue => checkedOptionValue === categoryOption.value),
        }));

        onChange(changedOptions);
    };

    return (
        <FilterBlock
            title={trans('common.category')}
            className={`pz-logs-categories-filter-container ${className}`}
        >
            <CheckboxList
                hideLabel
                label={trans('common.category')}
                name={trans('common.category')}
                options={categoryOptions}
                value={selectedCategoryOptions.map(option => option.value)}
                onChange={handleOptionsChange}
            />
        </FilterBlock>
    );
};

export default PzLogCategoriesFilterContainer;
