import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { EventLogActionType } from '../../types/eventLogTypes';
import { EventLogEntityType } from './EventLog';
import {
    LegacyEventLog,
    LegacyEventLogChange,
    LegacyEventLogChangeResource,
    LegacyEventLogDataItem,
    LegacyEventLogDataItemResource,
    LegacyEventLogResource,
} from './LegacyEventLog';

export const transformToLegacyEventLogChange = (resource: LegacyEventLogChangeResource, key: string): LegacyEventLogChange => ({
    label: key,
    from: typeof resource.from === 'string' ? resource.from : JSON.stringify(resource.from),
    to: typeof resource.to === 'string' ? resource.to : JSON.stringify(resource.to),
});

export const transformToLegacyEventLogDataItem = (value: LegacyEventLogDataItemResource | null, key: string): LegacyEventLogDataItem => ({
    value: typeof value === 'string' ? value : JSON.stringify(value),
    label: key,
});

export const transformToLegacyEventLog = (resource: LegacyEventLogResource): LegacyEventLog => {
    const resourceChanges = resource.changes || {};
    const changes = Object
        .keys(resourceChanges)
        .reduce((total, key) => ([
            ...total,
            transformToLegacyEventLogChange(resourceChanges[key], key),
        ]), [] as LegacyEventLogChange[]);

    const resourceData = resource.data || {};
    const logDataItems = Object
        .keys(resourceData)
        .reduce((total, key) => ([
            ...total,
            transformToLegacyEventLogDataItem(resourceData[key], key),
        ]), [] as LegacyEventLogDataItem[]);

    const entityTypeKey = getEnumKeyByEnumValue(EventLogEntityType, resource.entityType);
    const actionTypeKey = getEnumKeyByEnumValue(EventLogActionType, resource.action);

    if (!entityTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.entityType}".`);
    }

    if (!actionTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.action}".`);
    }

    return {
        id: resource.uuid,
        action: actionTypeKey ? EventLogActionType[actionTypeKey] : EventLogActionType.created,
        categorySlug: resource.category,
        changes,
        createdAt: new Date(resource.createdAt),
        createdBy: resource.createdBy,
        data: logDataItems,
        entityType: entityTypeKey ? EventLogEntityType[entityTypeKey] : EventLogEntityType.other,
        entityUuid: resource.entityUuid,
        message: resource.message,
        updatedAt: new Date(resource.updatedAt),
    };
};
