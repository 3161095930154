import React, { FC, useState } from 'react';

import classnames from 'classnames';

import { getLogDateBlockDateString } from '../../../components/Logs/helpers';
import UserPageLink from '../../../components/UserPageLink/UserPageLink';
import { translate } from '../../../helpers/translations/translator';
import { TableCell, TableRow } from '../../components';
import { EventLog, EventLogDataItem } from '../../entities/EventLog/EventLog';
import { formatDate } from '../../helpers/date';
import { IconButton, ValkTable } from '../index';
import { convertDataItemObjectToValues } from '../PacoLogsModal/helpers';

import './PacoDetailLogs.scss';

export interface PacoDetailLogsProps {
    firstLogActive?: boolean;
    detailLogs: EventLog[];
    className?: string;
}

const PacoDetailLogs: FC<PacoDetailLogsProps> = ({
    firstLogActive = false,
    detailLogs,
    className = '',
}) => {
    const [isActive, setIsActive] = useState(firstLogActive);

    const toggleIsActive = () => setIsActive(prev => !prev);

    const dateString = detailLogs?.length && getLogDateBlockDateString(detailLogs[0].updatedAt, new Date());

    const buttonClassName = classnames('paco-detail-logs__date-button', {
        'paco-detail-logs__date-button--is-active': isActive,
    });

    return (
        <div className={`paco-detail-logs ${className}`}>
            <IconButton
                icon="chevron-down"
                iconPos="right"
                text={dateString || ''}
                onClick={toggleIsActive}
                className={buttonClassName}
                iconClassName="paco-detail-logs__date-button-icon"
            />
            <div className="paco-detail-logs__logs">
                {detailLogs.map(detailLog => (
                    <div
                        key={detailLog.id}
                        className="paco-detail-logs__log"
                    >
                        <div className="paco-detail-logs__circle" />
                        <div className="paco-detail-logs__mutations">
                            <div className="paco-detail-logs__title">
                                <span className="paco-detail-logs__bold">
                                    {detailLog.message}
                                </span>
                                <span>
                                    {`${formatDate(detailLog.updatedAt, 'HH:mm')} ${translate('common.by').toLowerCase()} `}
                                    <UserPageLink
                                        name={detailLog.createdBy}
                                        id={detailLog.id}
                                        className="paco-detail-logs__actor-link"
                                    />
                                </span>
                            </div>
                            <ValkTable<EventLogDataItem>
                                hasRowClick
                                rows={detailLog.eventLog}
                                className="paco-detail-logs__table"
                            >
                                {logs => logs.flatMap(log => (
                                    convertDataItemObjectToValues<string>(log).map(({ key, field, value }) => (
                                        <TableRow key={`${key}-${field}`} className="paco-detail-logs__subrow">
                                            <TableCell className="paco-detail-logs__value-cell">
                                                {field}
                                            </TableCell>
                                            <TableCell
                                                contentClassName="paco-detail-logs__content-value-change"
                                                className="paco-detail-logs__value-cell-change"
                                            >
                                                {value}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ))}
                            </ValkTable>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PacoDetailLogs;
