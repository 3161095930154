import { FC, useEffect } from 'react';

import { ChangeLogWidget } from '../../compositions';
import { fetchChangeLog } from '../../redux/dashboard/dashboardActions';
import { setHasViewedChangeLog } from '../../redux/dashboard/dashboardReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedChangeLogWidgetProps {
    className?: string;
}

const ConnectedChangeLogWidget: FC<ConnectedChangeLogWidgetProps> = ({ className }) => {
    const dispatch = useTypedDispatch();

    const { isTokenValidated } = useTypedSelector(state => state.authenticatedUserReducer);

    const {
        hasUpdatedChangeLog,
        isChangeLogLoading,
        changeLog,
        hasViewedChangeLog,
    } = useTypedSelector(state => state.dashboardSettingsReducer);

    const handleViewedChangeLog = () => {
        dispatch(setHasViewedChangeLog(true));
    };

    useEffect(() => {
        // Fetch the changelog once the user is authenticated
        // Otherwise, a login redirect due to a rejected token may incorrectly flag the changelog as 'seen'
        if (isTokenValidated) {
            dispatch(fetchChangeLog());
        }
    }, [isTokenValidated]);

    return (
        <ChangeLogWidget
            hasUpdatedChangeLog={hasUpdatedChangeLog}
            isLoading={isChangeLogLoading || !isTokenValidated}
            changeLog={changeLog}
            hasViewedChangeLog={hasViewedChangeLog}
            onViewChangeLog={handleViewedChangeLog}
            className={className}
        />
    );
};

export default ConnectedChangeLogWidget;
