import { EditEmergencyContactFormData, EmergencyContactFormData } from '../../entities/EmergencyContact/EmergencyContact';
import { patchEmergencyContactApiCall, postEmergencyContactApiCall } from '../../entities/EmergencyContact/EmergencyContactService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { NotificationCenterLog } from '../../entities/NotificationCenter/NotificationCenterLog';

export const addEmergencyContact = async (emergencyContact: EmergencyContactFormData, personId: string, eventLogTrackerData?: NotificationCenterLog): Promise<void> => {
    const response = await postEmergencyContactApiCall(emergencyContact, personId, eventLogTrackerData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addEmergencyContact');
    }
};

export const editEmergencyContact = async (emergencyContact: EditEmergencyContactFormData, personId: string, eventLogTrackerData?: NotificationCenterLog): Promise<void> => {
    const response = await patchEmergencyContactApiCall(emergencyContact, personId, eventLogTrackerData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editEmergencyContact');
    }
};
