import { EventLogActionType } from '../../types/eventLogTypes';

export enum EntityCategory {
    // Profile
    Address = 'Adres',
    DivergentPreEmploymentHour = 'Afwijkende contracturen',
    Employment = 'Dienstverband',
    Person = 'Persoon',
    User = 'Gebruiker',
}

export enum EntityCategoryItem {
    // Address
    houseNumber = 'Huisnummer',
    houseNumberAddition = 'Huisnummer toevoeging',
    city = 'Plaats',

    // DivergentPreEmploymentHour
    contractHours = 'Contracturen',

    // Employment
    start = 'Startdatum dienstverband',

    // Person
    firstname = 'Voornaam',
    lastname = 'Achternaam',
    gender = 'Geslacht',
    affix = 'Tussenvoegsel',
    workDays = 'Werkdagen',
    isCompanyMedic = 'Bhv\'er',

    // User
    phone = 'Telefoonnummer',
    isEligibleForTimeForTime = 'Tijd voor tijd',
    employeeNumber = 'Medewerkernummer',
}

export enum EventLogEntityType {
    absence = 'App\\\\Entity\\\\Absence',
    absenceHour = 'App\\\\Entity\\\\AbsenceHour',
    comment = 'App\\\\Entity\\\\Comment',
    mail = 'App\\\\V3\\\\Helper\\\\EventLogger\\\\DTO\\\\MailEventLog',
    other = 'other',
    shift = 'App\\\\Entity\\\\Shift',
    track = 'App\\\\Entity\\\\Track',
    user = 'App\\\\Entity\\\\User',
    person = 'App\\\\Entity\\\\Person',
    divergentPreEmploymentHour = 'App\\\\Entity\\\\DivergentPreEmploymentHour',
}

export interface EventLogDataItem {
    eventLogData: {
        [key: string]: Record<string, string> | string;
    }
    title: string;
    action: string;
    category: string;
    subTitle: string;
    entityType: string;
    entityUuid: string;
}

export interface EventLog {
    id: string;
    personUuid: string;
    name: string;
    summary: string;
    action: EventLogActionType;
    categorySlug: string;
    createdAt: Date;
    createdBy: string;
    eventLog: EventLogDataItem[];
    entityType: EventLogEntityType;
    entityUuid: string;
    message: string;
    updatedAt: Date;
}

export interface EventLogDataItemBodyResource {
    _summary: string;
    _entity: {
        fullName: string;
        uuid: string;
    }
    [key: string]: Record<string, string> | string;
}

export interface EventLogDataItemResource {
    body: EventLogDataItemBodyResource;
    title: string;
    action: string;
    category: string;
    subTitle: string;
    entityType: string;
    entityUuid: string
}

export interface EventLogResource {
    uuid: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    event: string;
    data: EventLogDataItemResource
    actionUuid: string;
}

