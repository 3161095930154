import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../components';
import { Dots, NumpadInput } from '../../compositions';
import { UserProfile } from '../../entities/User/User';
import trans from '../../helpers/trans';
import { NumpadFunctionType } from '../../types';

import './Pincode.scss';

interface PincodeProps {
    user: UserProfile | undefined;
    onLogoutButtonClick: () => void;
    onSubmitPin: (pin: string) => void;
    errorMessage?: string;
    className?: string;
}

const pinMaxLength = 4;

const Pincode: FC<PincodeProps> = ({
    user,
    onLogoutButtonClick,
    onSubmitPin,
    errorMessage = '',
    className = '',
}): ReactElement => {
    const [pin, setPin] = useState<string>('');

    const handleNumpadButtonKeyUp = (value: number | NumpadFunctionType): void => {
        if (value === NumpadFunctionType.backspace) {
            setPin(pin.substring(0, pin.length - 1));

            return;
        }

        setPin(`${pin}${value}`.substring(0, pinMaxLength));
    };

    useEffect((): void => {
        if (pin.length === pinMaxLength) {
            const parsedPin = parseInt(pin, 10);

            if (Number.isNaN(parsedPin)) {
                console.error('Pin is not a number');

                return;
            }

            onSubmitPin(pin);
        }
    }, [pin]);

    useEffect((): void => {
        if (errorMessage) {
            setPin('');
        }
    }, [errorMessage]);

    return (
        <div className={`pincode ${className}`}>
            <h2 className="pincode__title">
                {trans('containers.pincode.title', { name: user?.person?.firstname || '' })}
            </h2>

            <p className="pincode__subtitle">
                {trans('containers.pincode.subtitle')}
            </p>

            <div className="pincode__numpad">
                <Dots
                    amount={pinMaxLength}
                    filledAmount={pin.length}
                    className="pincode__dots"
                />

                <NumpadInput
                    onButtonKeyUp={handleNumpadButtonKeyUp}
                    className="pincode__numpad-input"
                />
            </div>

            {errorMessage && (
                <p className="pincode__error-message">
                    {errorMessage}
                </p>
            )}

            <p className="pincode__contact">
                {trans('containers.pincode.contact')}
            </p>

            <Button
                text={trans('common.logout')}
                onClick={onLogoutButtonClick}
                className="pincode__logout-button"
            />
        </div>
    );
};

export default Pincode;
