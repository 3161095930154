import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import DeleteResourceForm from '../../components/forms/DeleteResourceForm/DeleteResourceForm';
import ItemsList from '../../components/ItemsList/ItemsList';
import LeaveOfAbsenceRequestCard from '../../components/LeaveOfAbsenceRequestCard/LeaveOfAbsenceRequestCard';
import LogsModal from '../../components/Logs/LogsModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import { LEAVE_OF_ABSENCE_REQUESTS, LEAVE_OF_ABSENCES_REQUEST_OPEN } from '../../constants';
import { checkPermission } from '../../helpers';
import { getRangeFromDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { LeaveOfAbsenceEditType } from '../../models';
import { addLeaveOfAbsenceRequest, editLeaveOfAbsence, getLeaveOfAbsenceRequests } from '../../redux/absences-ts/absencesActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { clearResourceLogs, getResourceLogs } from '../../redux/logs/logsActions';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import AddLeaveOfAbsenceForm from './forms/AddLeaveOfAbsenceForm';
import ApproveLeaveOfAbsenceForm from './forms/ApproveLeaveOfAbsenceForm/ApproveLeaveOfAbsenceForm';
import DeclineLeaveOfAbsenceForm from './forms/DeclineLeaveOfAbsenceForm';
import ResolveLeaveOfAbsenceForm from './forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';

import './Absences.scss';

class LeaveOfAbsenceRequests extends Component {
    state = {
        resolveAbsenceForm: false,
        removeForm: false,
        declineForm: false,
        approveForm: false,
        absenceData: null,
        addLeaveOfAbsenceForm: false,
    };

    componentDidMount() {
        this.getLeaveOfAbsences();
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getLeaveOfAbsences();
        }
    }

    dispatchRemoveLeaveOfAbsence = () => {
        this.setState({ removeForm: false });
        this.props.dispatch(editLeaveOfAbsence({
            editType: LeaveOfAbsenceEditType.delete,
            leaveOfAbsence: this.state.absenceData,
        }));
    };

    dispatchDeclineLeaveOfAbsence = (declineComment) => {
        this.setState({
            declineForm: false,
            absenceData: null,
        });

        this.props.dispatch(editLeaveOfAbsence({
            editType: 'deny',
            leaveOfAbsence: this.state.absenceData,
            declineComment,
        }));
    };

    dispatchApproveLeaveOfAbsence = (payrollPeriodsWithHours, weeksWithHours, leaveType) => {
        this.setState({
            approveForm: false,
            absenceData: null,
        });

        this.props.dispatch(editLeaveOfAbsence({
            editType: 'accept',
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveOfAbsence: this.state.absenceData,
            leaveType,
        }));
    };

    dispatchEditLeaveOfAbsence = (
        editType,
        data,
        periodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        const { absenceData } = this.state;

        this.setState({
            resolveAbsenceForm: false,
            absenceData: null,
        });

        this.props.dispatch(editLeaveOfAbsence({
            editType,
            data,
            leaveType,
            payrollPeriodsWithHours: periodsWithHours,
            weeksWithHours,
            leaveOfAbsence: absenceData,
        }));
    };

    dispatchAddLeaveOfAbsence = (
        userId,
        data,
        periodsWithHours,
        weeksWithHours,
        comment,
        leaveType,
    ) => {
        this.props.dispatch(addLeaveOfAbsenceRequest({
            userId,
            data,
            periodsWithHours,
            weeksWithHours,
            comment,
            leaveType,
        }));
        this.setState({
            addLeaveOfAbsenceForm: false,
        });
    };

    dispatchSetDate = (date) => {
        const { timeMode } = this.props;
        const days = getRangeFromDate(date, timeMode);
        this.props.dispatch(setStartAndEndDate(days[0], days[0], days[days.length - 1]));
    };

    onSubmitLeaveOfAbsenceForm = (
        editType,
        data,
        payrollPeriodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        switch (editType) {
            case LeaveOfAbsenceEditType.deny:
                this.addDeclineLeaveOfAbsenceForm();
                break;
            case LeaveOfAbsenceEditType.delete:
                this.setState({
                    removeForm: true,
                    resolveAbsenceForm: false,
                });
                break;
            default:
                this.dispatchEditLeaveOfAbsence(
                    editType,
                    data,
                    payrollPeriodsWithHours,
                    weeksWithHours,
                    leaveType,
                );
                break;
        }
    };

    addDeclineLeaveOfAbsenceForm = () => {
        this.setState({
            resolveAbsenceForm: false,
            declineForm: true,
        });
    };

    dispatchOpenLogsModal = (id) => {
        this.props.dispatch(getResourceLogs(id, 'leave-of-absences'));
    };

    dispatchCloseLogsModal = () => {
        this.props.dispatch(clearResourceLogs());
    };

    dispatchSetFilters = (filter) => {
        this.props.dispatch(setFilter(filter, this.getLeaveOfAbsences));
    };

    onSearchBarChange = (value) => {
        this.props.dispatch(setEmployeeSearch(value));
        this.getLeaveOfAbsences();
    };

    getLeaveOfAbsences = () => {
        this.props.dispatch(getLeaveOfAbsenceRequests(LEAVE_OF_ABSENCE_REQUESTS, LEAVE_OF_ABSENCES_REQUEST_OPEN));
    };

    addLeaveOfAbsence = () => {
        this.setState({
            addLeaveOfAbsenceForm: true,
        });
    };

    renderLeaveOfAbsences() {
        const {
            removeForm,
            declineForm,
            approveForm,
            addLeaveOfAbsenceForm,
            absenceData,
            resolveAbsenceForm,
        } = this.state;
        const {
            filter,
            employees,
            openLeaveOfAbsencesRequests,
            loading,
            pagination,
            permissions,
            payrollPeriods,
            pacoPayrollPeriods,
            logs = null,
            logsLoading,
            currentUser,
        } = this.props;
        const { employeeSearch } = filter;

        const existsInLoket = absenceData?.leaveOfAbsenceHours?.some((hours) => hours.loketIds?.length);

        return (
            <>
                <div className="absences-search">
                    <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                </div>
                <ItemsList
                    loading={loading}
                    itemsLength={openLeaveOfAbsencesRequests.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.absences.emptyLeaveOfAbsencesList')}
                    onPagination={this.getLeaveOfAbsences}
                >
                    <div className="leave-of-absence-requests">
                        <ConnectedHelpTooltip
                            index={2}
                            route="leave-of-absence-requests"
                            subTitle={trans('help.absences.leaveOfAbsenceRequestCard.title')}
                            text={trans('help.absences.leaveOfAbsenceRequestCard.text')}
                            title={trans('help.absences.title')}
                            className="absences__leave-of-absence-request-card-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={3}
                            route="leave-of-absence-requests"
                            subTitle={trans('help.absences.seeLeaveOfAbsencesInCalendar.title')}
                            text={trans('help.absences.seeLeaveOfAbsencesInCalendar.text')}
                            title={trans('help.absences.title')}
                            className="absences__see-leave-of-absences-in-calendar-help-tooltip"
                        />
                        {openLeaveOfAbsencesRequests.map(absence => (
                            <LeaveOfAbsenceRequestCard
                                key={absence.id}
                                absence={absence}
                                currentUserRole={currentUser.role}
                                permissions={permissions}
                                payrollPeriods={pacoPayrollPeriods}
                                onApprove={() => this.setState({
                                    approveForm: true, absenceData: absence,
                                })}
                                onDecline={() => this.setState({
                                    declineForm: true, absenceData: absence,
                                })}
                                onOpenCalendar={() => this.dispatchSetDate(absence.start)}
                                onShowLogsClick={() => this.dispatchOpenLogsModal(absence.id)}
                                onEditClick={() => this.setState({
                                    absenceData: absence, resolveAbsenceForm: true,
                                })}
                            />
                        ))}
                    </div>
                </ItemsList>
                <Modal size="lg" isOpen={declineForm} className="form-decline-leave-of-absence">
                    <DeclineLeaveOfAbsenceForm
                        onSubmit={this.dispatchDeclineLeaveOfAbsence}
                        onCancel={() => this.setState({ declineForm: false, absenceData: null })}
                    />
                </Modal>
                <Modal size="lg" isOpen={removeForm} className="form-decline-leave-of-absence">
                    <DeleteResourceForm
                        title={translate('pages.absences.deleteLeaveOfAbsence')}
                        intro={
                            existsInLoket
                                ? translate('pages.absences.deleteLeaveOfAbsenceWarningWithLoketInfo')
                                : translate('pages.absences.deleteLeaveOfAbsenceWarning')
                        }
                        onSubmit={() => this.dispatchRemoveLeaveOfAbsence(this.state.absenceData)}
                        onCancel={() => this.setState({ removeForm: false, absenceData: null })}
                    />
                </Modal>
                <Modal size="lg" isOpen={approveForm} className="form-approve-leave-of-absence">
                    <ApproveLeaveOfAbsenceForm
                        leaveOfAbsence={absenceData}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.dispatchApproveLeaveOfAbsence}
                        onCancel={() => this.setState({ approveForm: false, absenceData: null })}
                    />
                </Modal>
                <Modal size="lg" isOpen={addLeaveOfAbsenceForm} className="form-add-leave-of-absence">
                    <AddLeaveOfAbsenceForm
                        employees={employees}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.dispatchAddLeaveOfAbsence}
                        onCancel={() => this.setState({ addLeaveOfAbsenceForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={resolveAbsenceForm} className="form-resolve-leave-of-absence">
                    <ResolveLeaveOfAbsenceForm
                        absence={absenceData}
                        currentUserRole={currentUser.role}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.onSubmitLeaveOfAbsenceForm}
                        onCancel={() => this.setState({ resolveAbsenceForm: false })}
                        onShowLogsClick={() => this.dispatchOpenLogsModal(absenceData.id)}
                    />
                </Modal>
                <LogsModal
                    loading={logsLoading}
                    isOpen={logsLoading || !!logs}
                    title={translate('logs.leaveOfAbsenceLog')}
                    logs={logs}
                    onClose={this.dispatchCloseLogsModal}
                />
            </>
        );
    }

    render() {
        const {
            departments,
            filter,
            permissions,
            roles,
            employmentTypes,
        } = this.props;
        const canAddLeaveOfAbsence = checkPermission(permissions, 'add-new-leave-of-absences')
            || checkPermission(permissions, 'add-new-employee-leave-of-absences')
            || checkPermission(permissions, 'add-new-manager-leave-of-absences')
            || checkPermission(permissions, 'add-new-junior-manager-leave-of-absences');

        return (
            <>
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            departments={departments}
                            filter={filter}
                            roles={roles}
                            employmentTypes={employmentTypes}
                            button={
                                canAddLeaveOfAbsence ? (
                                    <ButtonAuthenticate onClick={this.addLeaveOfAbsence} text={translate('pages.absences.addLeaveOfAbsence')} />
                                ) : null
                            }
                            filterBlocks={[
                                'user-types',
                                'contract-types',
                                'main-department',
                            ]}
                            onChange={this.dispatchSetFilters}
                        />
                    )}
                >
                    <div className="absences-list">
                        {this.renderLeaveOfAbsences()}
                    </div>
                    <ConnectedHelpTooltip
                        index={0}
                        route="leave-of-absence-requests"
                        subTitle={trans('help.absences.addLeaveOfAbsence.title')}
                        text={trans('help.absences.addLeaveOfAbsence.text')}
                        title={trans('help.absences.title')}
                        className="absences__add-leave-of-absence-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={1}
                        route="leave-of-absence-requests"
                        subTitle={trans('help.absences.leaveOfAbsencesFilters.title')}
                        text={trans('help.absences.leaveOfAbsencesFilters.text')}
                        title={trans('help.absences.title')}
                        showMobileInfoWarning
                        className="absences__filters-help-tooltip"
                    />
                </SidebarPage>
                <ButtonAuthenticate
                    onClick={this.addLeaveOfAbsence}
                    mobile
                />
            </>
        );
    }
}

LeaveOfAbsenceRequests.propTypes = {
    loading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    openLeaveOfAbsencesRequests: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    timeMode: PropTypes.string.isRequired,
    pagination: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    logs: PropTypes.array,
    logsLoading: PropTypes.bool.isRequired,
};

export default connect()(LeaveOfAbsenceRequests);
