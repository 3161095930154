import { FC, ReactElement, useEffect } from 'react';

import EntityEventLogs from '../../containers/EntityEventLogs/EntityEventLogs';
import { LegacyEventLog } from '../../entities/EventLog/LegacyEventLog';
import { getEventLogsForEntity } from '../../redux/eventLogs/eventLogsActions';
import { reset } from '../../redux/eventLogs/eventLogsReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { EventLogEntityType } from '../../types/eventLogTypes';

interface ConnectedEntityEventLogsProps {
    entityId: string;
    entityType?: EventLogEntityType;
}

const ConnectedEntityEventLogs: FC<ConnectedEntityEventLogsProps> = ({ entityId, entityType }): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, eventLogs } = useTypedSelector(state => state.eventLogsReducer);

    const legacyEventLogs = eventLogs as LegacyEventLog[];

    useEffect((): () => void => {
        dispatch(getEventLogsForEntity(entityId, entityType));

        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <EntityEventLogs
            isLoading={isLoading}
            logs={legacyEventLogs}
        />
    );
};

export default ConnectedEntityEventLogs;
