import { ContractHours } from '../../entities/ContractHours/ContractHours';
import { isDeleteContractHoursFormData, isEditContractHoursFormData } from '../../entities/ContractHours/ContractHoursHelpers';
import { addContractHourApiCall, deleteContractHourApiCall, editContractHourApiCall } from '../../entities/ContractHours/ContractHoursService';
import { FetchResult, isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { fetchLoketEmployeeApiCall, syncUserToLoketApiCall } from '../../entities/LoketUser/LoketUserService';
import {
    NotificationCenterAction,
    NotificationCenterActionCategory,
    NotificationCenterCategory,
} from '../../entities/NotificationCenter/NotificationCenterLog';
import {
    endEventLoggerApiCall,
    startEventLoggerApiCall,
} from '../../entities/NotificationCenter/NotificationCenterService';
import { Period } from '../../entities/Period/Period';
import { getTracksApiCall } from '../../entities/Track/TrackService';
import {
    AddUserFormData,
    EditUserFormData,
    FullUser,
    UserProfile,
} from '../../entities/User/User';
import { getJustifiedEditUserFormData } from '../../entities/User/UserHelpers';
import {
    getFullUserApiCall,
    getUserProfileV3ApiCall,
    patchUserApiCall,
    patchUserPermissionsApiCall,
    postUserApiCall,
    postUserResendRegistrationApiCall,
    resetUserPasswordApiCall,
    setUserStatusApiCall,
} from '../../entities/User/UserService';
import trans from '../../helpers/trans';
import { UserStatus } from '../../types/userStatus';
import {
    addDivergentPostEmploymentHourContractHours,
    editDivergentPostEmploymentHourContractHours,
} from '../divergentPostEmploymentHour/divergentPostEmploymentHourActions';
import {
    addDivergentPreEmploymentHourContractHours,
    editDivergentPreEmploymentHourContractHours,
} from '../divergentPreEmploymentHour/divergentPreEmploymentHourActions';
import { editEmployment } from '../employment/employmentActions';
import { addPerson, editPerson } from '../person/personActions';
import { TypedDispatch } from '../store';
import {
    setError,
    setFullUser,
    setIsFullUserLoading,
    setIsFullUserSuccessful,
    setIsLoading,
    setIsLoketUserLoading,
    setIsPermissionsLoading,
    setIsResendRegistrationSuccessful,
    setIsResetUserPasswordSuccessful,
    setIsSetUserStatusSuccessful,
    setIsSuccessful,
    setIsSyncUserFailed,
    setIsSyncUserLoading,
    setIsSyncUserSuccessful,
    setIsUserProfileLoading,
    setIsUserTracksLoading,
    setLoketError,
    setLoketUser,
    setUser,
    setUserProfile,
    setUserTracks,
} from './userReducer';

export const addUser = (addUserFormData: AddUserFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    const responseTracker = await startEventLoggerApiCall(
        NotificationCenterCategory.USER,
        NotificationCenterAction.CREATED,
        [NotificationCenterActionCategory.UNKNOWN],
    );

    if (!isFetchResultSuccessful(responseTracker)) {
        dispatch(setError(responseTracker.error));

        return;
    }

    try {
        const response = await postUserApiCall(addUserFormData, responseTracker.data);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        await addPerson(addUserFormData, response.data.id, responseTracker.data);

        let employmentActions: Promise<void>[] = [];
        const contractHoursActions: Promise<FetchResult<ContractHours, string>>[] = [];

        if (response.data.employment && addUserFormData.employmentDates) {
            employmentActions = [
                editEmployment(response.data.employment.id, addUserFormData.employmentDates, responseTracker.data),
                ...(addUserFormData.employmentDates.divergentPostEmploymentHours !== undefined
                    ? [addDivergentPostEmploymentHourContractHours(response.data.employment.id, addUserFormData.employmentDates, responseTracker.data)]
                    : []),
                ...(addUserFormData.employmentDates.divergentPreEmploymentHours !== undefined
                    ? [addDivergentPreEmploymentHourContractHours(response.data.employment.id, addUserFormData.employmentDates, responseTracker.data)]
                    : []),
            ];
        }

        if (addUserFormData.contractHours) {
            addUserFormData.contractHours.forEach(contractHours => {
                contractHoursActions.push(addContractHourApiCall(contractHours, response.data.id, responseTracker.data));
            });
        }

        await Promise.all([...employmentActions, ...contractHoursActions]);

        dispatch(setUser(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addUser]', error);
        dispatch(setError(trans('errors.unknownError')));
    } finally {
        await endEventLoggerApiCall(responseTracker.data);
        dispatch(setIsLoading(false));
    }
};

export const editUser = (editUserFormData: EditUserFormData, fullUser: FullUser) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    const responseTracker = await startEventLoggerApiCall(
        NotificationCenterCategory.USER,
        NotificationCenterAction.UPDATED,
        [NotificationCenterActionCategory.UNKNOWN],
    );

    if (!isFetchResultSuccessful(responseTracker)) {
        dispatch(setError(responseTracker.error));

        return;
    }

    // Remove any unnecessary data from the form data to reduce api calls
    const formData = getJustifiedEditUserFormData(editUserFormData, fullUser);

    try {
        const response = await patchUserApiCall(formData, responseTracker.data);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        await editPerson(formData, formData.id, responseTracker.data);

        const employmentActions: Promise<void>[] = [];
        const contractHoursActions: Promise<FetchResult<ContractHours | boolean, string>>[] = [];

        if (response.data.employment && formData.employmentDates) {
            employmentActions.push(editEmployment(response.data.employment.id, formData.employmentDates, responseTracker.data));

            const { divergentPostEmploymentHour, divergentPreEmploymentHour } = response.data.employment;

            if (formData.employmentDates.divergentPostEmploymentHours !== undefined) {
                if (divergentPostEmploymentHour) {
                    employmentActions.push(editDivergentPostEmploymentHourContractHours(divergentPostEmploymentHour.id, formData.employmentDates, responseTracker.data));
                } else {
                    employmentActions.push(addDivergentPostEmploymentHourContractHours(response.data.employment.id, formData.employmentDates, responseTracker.data));
                }
            }

            if (formData.employmentDates.divergentPreEmploymentHours !== undefined) {
                if (divergentPreEmploymentHour) {
                    employmentActions.push(editDivergentPreEmploymentHourContractHours(divergentPreEmploymentHour.id, formData.employmentDates, responseTracker.data));
                } else {
                    employmentActions.push(addDivergentPreEmploymentHourContractHours(response.data.employment.id, formData.employmentDates, responseTracker.data));
                }
            }
        }

        if (formData.contractHours) {
            formData.contractHours.forEach(contractHours => {
                if (isDeleteContractHoursFormData(contractHours)) {
                    contractHoursActions.push(deleteContractHourApiCall(contractHours, responseTracker.data));
                } else if (isEditContractHoursFormData(contractHours)) {
                    contractHoursActions.push(editContractHourApiCall(contractHours, responseTracker.data));
                } else {
                    contractHoursActions.push(addContractHourApiCall(contractHours, response.data.id, responseTracker.data));
                }
            });
        }

        await Promise.all([...employmentActions, ...contractHoursActions]);

        dispatch(setUser(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editUser]', error);
    } finally {
        await endEventLoggerApiCall(responseTracker.data);
        dispatch(setIsLoading(false));
    }
};

export const getFullUser = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsFullUserLoading(true));
    dispatch(setIsFullUserSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getFullUserApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setFullUser(response.data));
        dispatch(setIsFullUserSuccessful(true));
    } catch (error) {
        console.error('[getFullUser]', error);
    } finally {
        dispatch(setIsFullUserLoading(false));
    }
};

export const getUserTracks = (period: Period, userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUserTracksLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getTracksApiCall(period, { userIds: [userId] });

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUserTracks(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[getUserTracks]', error);
    } finally {
        dispatch(setIsUserTracksLoading(false));
    }
};

export const resendUserRegistration = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsResendRegistrationSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postUserResendRegistrationApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsResendRegistrationSuccessful(true));
    } catch (error) {
        console.error('[resendUserRegistration]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getUserProfileV3 = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUserProfileLoading(true));
    dispatch(setError(''));

    try {
        const response = await getUserProfileV3ApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUserProfile(response.data));
    } catch (error) {
        console.error('[getUserProfileV3]', error);
    } finally {
        dispatch(setIsUserProfileLoading(false));
    }
};

export const getLoketUser = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoketUserLoading(true));
    dispatch(setLoketError(''));

    try {
        const response = await fetchLoketEmployeeApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setLoketError(response.error));

            return;
        }

        dispatch(setLoketUser(response.data));
    } catch (error) {
        console.error('[getLoketUser]', error);
    } finally {
        dispatch(setIsLoketUserLoading(false));
    }
};

export const resetUserPassword = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsResetUserPasswordSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await resetUserPasswordApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsResetUserPasswordSuccessful(true));
    } catch (error) {
        console.error('[resetUserPassword]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editUserStatus = (user: UserProfile, status: UserStatus) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSetUserStatusSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await setUserStatusApiCall(user.id, status);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUserProfile({
            ...user,
            status,
        }));
        dispatch(setIsSetUserStatusSuccessful(true));
    } catch (error) {
        console.error('[editUserStatus]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const patchUserPermissions = (
    permissionIds: string[],
    user: UserProfile,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));
    dispatch(setIsPermissionsLoading(true));

    try {
        const response = await patchUserPermissionsApiCall(permissionIds, user.id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUserProfile({
            ...user,
            permissions: response.data,
        }));
    } catch (error) {
        console.error('[patchUserPermissions]', error);
    }
    dispatch(setIsPermissionsLoading(false));
};

export const syncUserToLoket = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));
    dispatch(setIsSyncUserSuccessful(false));
    dispatch(setIsSyncUserFailed(false));
    dispatch(setIsSyncUserLoading(true));

    try {
        const response = await syncUserToLoketApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            dispatch(setIsSyncUserFailed(true));

            return;
        }

        if (!response.data.isSuccessful) {
            dispatch(setIsSyncUserFailed(true));

            return;
        }

        dispatch(setIsSyncUserSuccessful(true));
    } catch (error) {
        console.error('[syncUserToLoket]', error);
        dispatch(setIsSyncUserFailed(true));
    } finally {
        dispatch(setIsSyncUserLoading(false));
    }
};
