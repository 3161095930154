import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { getOverlappingPayrollPeriodWeeks } from '../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeekHelpers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import {
    AbsenceHours,
    LeaveOfAbsenceHours,
    PayrollPeriodViewModel,
    WeekWithHours,
} from '../../models';
import { getDatesFromWeekNumber } from './getDatesFromWeekNumber';

export default function transformAbsenceHourToWeekdayWithHours(
    hours: AbsenceHours | LeaveOfAbsenceHours,
    payrollPeriods: PayrollPeriod[],
): WeekWithHours {
    const { start, end } = getDatesFromWeekNumber(hours.payrollPeriod?.year, hours.weekNumber);
    const payrollPeriodWeeks = getOverlappingPayrollPeriodWeeks(transformToPeriod(start, end.date), payrollPeriods);

    return {
        id: hours.id,
        weekNumber: hours.weekNumber,
        start,
        end,
        hours: hours.hours,
        payrollPeriod: (hours.payrollPeriod as PayrollPeriodViewModel) || null,
        payrollPeriodWeeks,
    };
}
