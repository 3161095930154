import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { NotificationCenterHeaderName, NotificationCenterLog } from '../NotificationCenter/NotificationCenterLog';
import { EmploymentDatesFormData } from '../User/User';
import { Employment, EmploymentResource } from './Employment';
import { transformEmploymentAndFormDataToRequest, transformToEmployment } from './EmploymentTransformers';

export const patchEmploymentApiCall = async (employmentId: string, formData: EmploymentDatesFormData, eventLogTrackerData?: NotificationCenterLog): Promise<FetchResult<Employment, string>> => {
    const apiData = transformEmploymentAndFormDataToRequest(employmentId, formData);

    try {
        const url = generateApiUrl({
            endpoint: `/employments/${employmentId}`,
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EmploymentResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const employmentData = transformToEmployment(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: employmentData,
        };
    } catch (error) {
        console.error('[patchEmploymentApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
