import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../../components';
import { IconButton } from '../../../../../compositions';
import { EventLog } from '../../../../../entities/EventLog/EventLog';
import { EventLogCategory } from '../../../../../entities/EventLogCategory/EventLogCategory';
import { formatDate } from '../../../../../helpers/date';
import trans from '../../../../../helpers/trans';
import { DateFormat } from '../../../../../types/dateFormatTypes';

import './PzLogRow.scss';

interface PzLogRowProps {
    category?: EventLogCategory;
    log: EventLog,
    onViewLogClick: (log: EventLog) => void;
    cellClassName?: string;
    className?: string;
}

const PzLogRow: FC<PzLogRowProps> = ({
    category,
    log,
    onViewLogClick,
    cellClassName = '',
    className = '',
}): ReactElement => {
    const handleButtonClick = () => {
        onViewLogClick(log);
    };

    return (
        <TableRow className={`pz-log-row ${className}`}>
            <TableCell
                className={cellClassName}
                contentClassName="pz-log-row__category"
            >
                {category?.name}
            </TableCell>
            <TableCell className={cellClassName}>
                {`${log.message}: ${log.name}`}
            </TableCell>
            <TableCell className={cellClassName}>
                {formatDate(log.createdAt, DateFormat.date)}
            </TableCell>
            <TableCell className={`pz-log-row__button-cell ${cellClassName}`}>
                <IconButton
                    hideLabel
                    icon="eye"
                    text={trans('common.open')}
                    onClick={handleButtonClick}
                    className="pz-log-row__button"
                    iconClassName="pz-log-row__button-icon"
                />
            </TableCell>
        </TableRow>
    );
};

export default PzLogRow;
