import { EntityCategory, EntityCategoryItem, EventLogDataItem } from '../../../entities/EventLog/EventLog';

export const translateCategory = (key: string): EntityCategory => EntityCategory[key as keyof typeof EntityCategory];
export const translateCategoryItem = (key: string): EntityCategoryItem => EntityCategoryItem[key as keyof typeof EntityCategoryItem];

export const convertEventLogDataToCategory = (log: EventLogDataItem): Array<{ key: EntityCategory, value: any }> => (
    Object.entries(log.eventLogData).map(([key, value]) => ({
        key: translateCategory(key as EntityCategory),
        value,
    }))
);

export const convertToCategoryItem = (value: string): Array<{ field: EntityCategoryItem, change: string}> => (
    Object.entries(value).map(([field, change]) => ({
        field: translateCategoryItem(field as EntityCategoryItem),
        change,
    }))
);
