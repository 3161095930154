import { DivergentPostEmploymentHour, DivergentPostEmploymentHourCheckedFormData } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHour';
import { patchDivergentPostEmploymentHourApiCall, postDivergentPostEmploymentHourApiCall } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHourService';
import { transformEmploymentDatesFormDataToDivergentPostEmploymentHourFormData } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHourTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { NotificationCenterLog } from '../../entities/NotificationCenter/NotificationCenterLog';
import { EmploymentDatesFormData } from '../../entities/User/User';
import { TypedDispatch } from '../store';
import { setError } from '../user/userReducer';
import { setDivergentPostEmploymentHour, setIsLoading } from './divergentPostEmploymentHourReducer';

export const addDivergentPostEmploymentHourContractHours = async (
    employmentId: string,
    employmentDatesFormData: EmploymentDatesFormData,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<void> => {
    const formData = transformEmploymentDatesFormDataToDivergentPostEmploymentHourFormData(employmentDatesFormData);

    formData.checked = false;

    const response = await postDivergentPostEmploymentHourApiCall(employmentId, formData, eventLogTrackerData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addDivergentPostEmploymentHourContractHours');
    }
};

export const editDivergentPostEmploymentHourContractHours = async (
    divergentPostEmploymentHourId: string,
    employmentDatesFormData: EmploymentDatesFormData,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<void> => {
    const formData = transformEmploymentDatesFormDataToDivergentPostEmploymentHourFormData(employmentDatesFormData);

    const response = await patchDivergentPostEmploymentHourApiCall(divergentPostEmploymentHourId, formData, eventLogTrackerData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editDivergentPostEmploymentHourContractHours');
    }
};

export const editDivergentPostEmploymentHourChecked = async (formData: DivergentPostEmploymentHourCheckedFormData): Promise<DivergentPostEmploymentHour> => {
    const response = await patchDivergentPostEmploymentHourApiCall(formData.divergentPostEmploymentHoursId, { checked: formData.isChecked });

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editDivergentPostEmploymentHourChecked');
    }

    return response.data;
};

export const setDivergentPostEmploymentHoursCheck = (formData: DivergentPostEmploymentHourCheckedFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await patchDivergentPostEmploymentHourApiCall(formData.divergentPostEmploymentHoursId, { checked: formData.isChecked });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDivergentPostEmploymentHour(response.data));
    } catch (error) {
        console.error('[setDivergentPostEmploymentHoursCheck]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
