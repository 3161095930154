import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { ElementWithTooltip, LeaveTypeSelectInput } from '../../../../@paco/compositions';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../../@paco/entities/Period/PeriodTransformers';
import { Permission } from '../../../../@paco/entities/Permission/Permission';
import { Role } from '../../../../@paco/entities/Role/Role';
import useCheckPermission from '../../../../@paco/helpers/hooks/useCheckPermission';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import ButtonHistory from '../../../../components/ButtonHistory/ButtonHistory';
import ButtonWithTooltip from '../../../../components/ButtonWithTooltip/ButtonWithTooltip';
import LeaveOfAbsenceInfo from '../../../../components/LeaveOfAbsenceRequestCard/LeaveOfAbsenceInfo';
import LegacyHoursWarning from '../../../../components/LegacyHoursWarning/LegacyHoursWarning';
import Icon from '../../../../components/style/Icon/Icon';
import WeekdayHoursInputs from '../../../../components/WeekHoursInputs/WeekHoursInputs';
import { LEAVE_OF_ABSENCES_TYPE_APPROVED, LEAVE_OF_ABSENCES_TYPE_DENIED, LEAVE_OF_ABSENCES_TYPE_OPEN } from '../../../../constants';
import { getObjProperty } from '../../../../helpers';
import { compareAsc, convertDateToApiParamDateTime, formatDate } from '../../../../helpers/date';
import UpToAndIncludingDate from '../../../../helpers/date/UpToAndIncludingDate';
import {
    getPermissionDeleteLeaveOfAbsence,
    getPermissionToEdit,
} from '../../../../helpers/permissions/getPermissionToAction';
import { translate } from '../../../../helpers/translations/translator';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../../models';
import transformWeeksWithHoursToPayrollPeriods from '../../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import { SetStartEndDateTimeForm } from '../SetStartEndDateTimeForm/SetStartEndDateTimeForm';
import { getLeaveTypeFromLeaveOfAbsenceHours, transformLeaveOfAbsenceHoursToWeekDayWithHours } from './index';

import '../Forms.scss';

export type EditLeaveOfAbsenceFormData = (
    type: LeaveOfAbsenceEditType,
    data: LeaveOfAbsenceFormData,
    payrollPeriodWithHours: PayrollPeriodWithHours[],
    weeksWithHours: WeekWithHours[],
    leaveType?: LeaveType | PzLeaveType | null,
) => void;

interface ResolveLeaveOfAbsenceFormProps {
    absence?: LeaveOfAbsence | null;
    currentUserRole?: Role;
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    permissions: Permission[];
    onCancel: () => void;
    onSubmit: EditLeaveOfAbsenceFormData;
    onShowLogsClick?: (id: string) => void;
}

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

const ResolveLeaveOfAbsenceForm: FC<ResolveLeaveOfAbsenceFormProps> = ({
    absence,
    currentUserRole,
    permissions,
    pacoPayrollPeriods,
    payrollPeriods,
    onCancel,
    onSubmit,
    onShowLogsClick,
}) => {
    const [leaveType, setLeaveType] = useState<LeaveType | PzLeaveType | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [periodsWithHours, setPeriodsWithHours] = useState<PayrollPeriodWithHours[]>([]);
    const [weeksWithHours, setWeekdaysWithHours] = useState<WeekWithHours[]>([]);
    const [legacyWeeksWithHours, setLegacyWeeksWithHours] = useState<WeekWithHours[]>([]);
    const [reason, setReason] = useState('');
    const [dateError, setDateError] = useState(false);

    const status = getObjProperty(absence, 'status');
    const userRoles = getObjProperty(absence, 'user.roles') || [];
    const canEditLeaveOfAbsence = getPermissionToEdit(currentUserRole, userRoles, permissions, 'leave-of-absences');
    const canEditLeaveOfAbsenceHours = getPermissionToEdit(currentUserRole, userRoles, permissions, 'leave-of-absence-hours');
    const userHasPermissionToDeleteLeaveOfAbsence = getPermissionDeleteLeaveOfAbsence(currentUserRole, permissions);
    const canViewAllLeaveTypes = useCheckPermission('view-all-leave-types');
    const canViewLogs = useCheckPermission('view-all-logs');

    const canDeleteLeaveOfAbsence = userHasPermissionToDeleteLeaveOfAbsence && (status === LEAVE_OF_ABSENCES_TYPE_OPEN || status === LEAVE_OF_ABSENCES_TYPE_DENIED);

    const payrollPeriodError = useMemo(() => {
        if (!startDate || !endDate) {
            return false;
        }

        return doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(new Date(startDate), new Date(endDate)),
            pacoPayrollPeriods,
        );
    }, [startDate, endDate, payrollPeriods]);

    const disabled = dateError
        || !canEditLeaveOfAbsence
        || !canEditLeaveOfAbsenceHours
        || (payrollPeriodError && status === LEAVE_OF_ABSENCES_TYPE_OPEN);

    const denyButtonDisabled = dateError
        || !canEditLeaveOfAbsence
        || !canEditLeaveOfAbsenceHours
        || payrollPeriodError;

    useEffect(() => {
        if (absence) {
            const weekdays = transformLeaveOfAbsenceHoursToWeekDayWithHours(
                absence.leaveOfAbsenceHours || [],
                pacoPayrollPeriods,
            );
            setWeekdaysWithHours(weekdays);
            setLegacyWeeksWithHours(weekdays);
            setLeaveType(getLeaveTypeFromLeaveOfAbsenceHours(absence.leaveOfAbsenceHours));
            setReason(absence.reason);

            setStartDate(formatDate(absence.start, DATE_FORMAT));
            setEndDate(formatDate(absence.end, DATE_FORMAT));
        }
    }, [absence]);

    const onChangeLeaveType = (type: LeaveType | PzLeaveType) => {
        setLeaveType(type);
    };

    const onSetStartEndDateTimeFormChange = (start: string, end: string) => {
        setDateError(compareAsc(start, end) !== -1);
        setStartDate(start);
        setEndDate(end);
    };

    const onReasonChange = (e: React.FormEvent<HTMLInputElement>) => {
        setReason(e.currentTarget.value);
    };

    const onWeekdayHoursInputsChange = (weekdays: WeekWithHours[]) => {
        const payrollPeriodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods);

        setWeekdaysWithHours(weekdays);
        setPeriodsWithHours(payrollPeriodsWithHours);
    };

    const getLOAParams = (): LeaveOfAbsenceFormData => ({
        start: startDate ? convertDateToApiParamDateTime(startDate) : '',
        end: endDate ? convertDateToApiParamDateTime(endDate) : '',
        reason,
    });

    const onDeleteClick = () => {
        onSubmit(
            LeaveOfAbsenceEditType.delete,
            getLOAParams(),
            periodsWithHours,
            weeksWithHours,
            leaveType,
        );
    };

    const onRejectClick = () => {
        const periodsWithZeroHours = periodsWithHours.map((periodWithHour) => ({
            ...periodWithHour,
            hours: 0,
        }));

        onSubmit(
            LeaveOfAbsenceEditType.deny,
            getLOAParams(),
            periodsWithZeroHours,
            weeksWithHours,
            leaveType,
        );
    };

    const onAcceptClick = () => {
        onSubmit(
            LeaveOfAbsenceEditType.accept,
            getLOAParams(),
            periodsWithHours,
            weeksWithHours,
            leaveType,
        );
    };

    const handleShowLogsClick = () => {
        if (onShowLogsClick && absence) {
            onShowLogsClick(absence.id);
        }
    };

    const onCancelClick = () => {
        if (absence?.start) {
            setStartDate(formatDate(absence?.start, DATE_FORMAT));
        }
        if (absence?.end) {
            setEndDate(formatDate(absence?.end, DATE_FORMAT));
        }

        onCancel();
    };

    const onEditClick = () => {
        onSubmit(
            LeaveOfAbsenceEditType.edit,
            getLOAParams(),
            periodsWithHours,
            weeksWithHours,
            leaveType,
        );
    };

    return (
        <div className="resolve-leave-of-absence">
            <ModalHeader>
                <span>{translate('pages.absences.resolveLeaveOfAbsenceForm')}</span>
                { (onShowLogsClick && canViewLogs) && <ButtonHistory className="form-resolve-leave-of-absence__button-history" onClick={handleShowLogsClick} /> }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <LeaveOfAbsenceInfo
                            absence={absence}
                        />
                    </Col>
                </Row>
                <div className="leave-of-absence-form__row">
                    <div className="leave-of-absence-form__col">
                        <LeaveTypeSelectInput
                            canViewAllLeaveTypes={canViewAllLeaveTypes}
                            leaveType={leaveType as PzLeaveType}
                            onChange={onChangeLeaveType}
                        />
                    </div>
                </div>
                <SetStartEndDateTimeForm
                    hasError={payrollPeriodError && status === LEAVE_OF_ABSENCES_TYPE_OPEN}
                    errorText={payrollPeriodError ? translate('common.datesOverlapWithLockedPayrollPeriod') : undefined}
                    startDate={startDate}
                    endDate={endDate}
                    showSpecialDays
                    onChange={onSetStartEndDateTimeFormChange}
                />
                <Row>
                    <Col>
                        <LegacyHoursWarning
                            weekWithHours={legacyWeeksWithHours}
                            type="leaveOfAbsences"
                        />
                    </Col>
                </Row>
                <WeekdayHoursInputs
                    disabled={payrollPeriodError && status === LEAVE_OF_ABSENCES_TYPE_OPEN}
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    weekWithHours={weeksWithHours}
                    type="leaveOfAbsences"
                    startDate={startDate ? new Date(startDate) : null}
                    endDate={endDate ? new UpToAndIncludingDate(endDate) : null}
                    onChange={onWeekdayHoursInputsChange}
                />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.description')}</Label>
                            <Input
                                type="text"
                                className="form-control form-absence-descr"
                                id="reason"
                                name="reason"
                                maxLength={255}
                                placeholder={translate('pages.absences.descriptionExample')}
                                value={reason}
                                onChange={onReasonChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={onCancelClick}>
                    {translate('common.cancel')}
                </Button>
                <Button disabled={disabled} type="button" color="orange" onClick={onEditClick} className="form-absence-button-edit">
                    {translate('common.edit')}
                </Button>
                { status !== LEAVE_OF_ABSENCES_TYPE_DENIED && (
                    <ButtonWithTooltip
                        tooltip={payrollPeriodError}
                        tooltipText={translate('pages.absences.leaveOfAbsenceRequestOverlapsWithLockedPeriod')}
                        disabled={denyButtonDisabled}
                        color="red"
                        className="form-absence-button-reject"
                        onClick={onRejectClick}
                    >
                        {translate('pages.absences.reject')}
                    </ButtonWithTooltip>
                )}
                { status !== LEAVE_OF_ABSENCES_TYPE_APPROVED && (
                    <Button disabled={disabled} type="submit" color="green" onClick={onAcceptClick} className="form-absence-button-accept">
                        {translate('common.accept')}
                    </Button>
                )}
                {canDeleteLeaveOfAbsence && (
                    <ElementWithTooltip
                        tooltipIsActive
                        className="leave-of-absence-form__table-tooltip"
                        tooltipText={translate('pages.absences.leaveOfAbsenceDeleteTooltip')}
                    >
                        <button
                            type="button"
                            onClick={onDeleteClick}
                            className="leave-of-absence-form__table-button"
                        >
                            <Icon
                                className="leave-of-absence-form__table-button-icon"
                                kind="trash"
                            />
                        </button>
                    </ElementWithTooltip>
                )}
            </ModalFooter>
        </div>
    );
};

export default ResolveLeaveOfAbsenceForm;
