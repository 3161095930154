import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { NotificationCenterHeaderName, NotificationCenterLog } from '../NotificationCenter/NotificationCenterLog';
import {
    Address,
    AddressFormData,
    AddressResource,
    EditAddressFormData,
} from './Address';
import { transformAddAddressFormDataToAddApiParams, transformEditAddressFormDataToEditApiParams, transformToAddress } from './AddressTransformers';

export const postAddressApiCall = async (
    addressFormData: AddressFormData,
    personId: string,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<Address, string>> => {
    const apiData = transformAddAddressFormDataToAddApiParams(addressFormData, personId);

    try {
        const url = generateApiUrl({ endpoint: '/addresses' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AddressResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const address = transformToAddress(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: address,
        };
    } catch (error) {
        console.error('[postAddressApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchAddressApiCall = async (
    editAddressFormData: EditAddressFormData,
    personId: string,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<Address, string>> => {
    const apiData = transformEditAddressFormDataToEditApiParams(editAddressFormData, personId);

    try {
        const url = generateApiUrl({ endpoint: `/addresses/${apiData.id}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AddressResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }
        const address = transformToAddress(doc.data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: address,
        };
    } catch (error) {
        console.error('[patchAddressApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
