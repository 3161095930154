import { isFetchResultSuccessful } from '../../entities/FetchResult';
import {
    getLeaveOfAbsencesInPeriodApiCall,
} from '../../entities/LeaveOfAbsence/LeaveOfAbsenceService';
import { Period } from '../../entities/Period/Period';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setLeaveOfAbsences } from './leaveOfAbsencesReducer';

export const getLeaveOfAbsencesInPeriod = (period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getLeaveOfAbsencesInPeriodApiCall(period, true);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLeaveOfAbsences(response.data));
    } catch (error) {
        console.error('[getLeaveOfAbsencesInPeriod]', error);
    }

    dispatch(setIsLoading(false));
};
