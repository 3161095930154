import { formatDate } from '../../../../helpers/date';
import { EventLog, EventLogDataItem } from '../../../entities/EventLog/EventLog';
import { translateCategory, translateCategoryItem } from '../../PzLogDetail/helpers';

export interface logsDateObjectProps {
    [key: string]: EventLog[]
}

export const generateLogsDateObject = (events: EventLog[]): logsDateObjectProps => events
    .reduce((total: logsDateObjectProps, log: EventLog) => {
        const date = formatDate(log.updatedAt, 'yyyy-MM-dd');

        return {
            ...total,
            [date]: [
                ...total[date] || [],
                log,
            ],
        };
    }, {});

export const convertDataItemObjectToValues = <T>(dataItem: EventLogDataItem): { key: string, field: string, value: T }[] => (
    Object.entries(dataItem.eventLogData).flatMap(([key, value]) => (
        Object.entries(value).map(([field, change]) => ({
            key: translateCategory(key),
            field: translateCategoryItem(field),
            value: change as T,
        }))
    ))
);
