import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { NotificationCenterHeaderName, NotificationCenterLog } from '../NotificationCenter/NotificationCenterLog';
import {
    EditEmergencyContactFormData,
    EmergencyContact,
    EmergencyContactFormData,
    EmergencyContactResource,
} from './EmergencyContact';
import { transformAddEmergencyContactFormDataToAddApiParams, transformEditEmergencyContactFormDataToEditApiParams, transformToEmergencyContact } from './EmergencyContactTransformers';

export const postEmergencyContactApiCall = async (
    emergencyContactFormData: EmergencyContactFormData,
    personId: string,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<EmergencyContact, string>> => {
    const apiData = transformAddEmergencyContactFormDataToAddApiParams(emergencyContactFormData, personId);

    try {
        const url = generateApiUrl({ endpoint: '/emergency-contacts' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EmergencyContactResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const emergencyContact = transformToEmergencyContact(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: emergencyContact,
        };
    } catch (error) {
        console.error('[postEmergencyContactApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchEmergencyContactApiCall = async (
    editEmergencyContactFormData: EditEmergencyContactFormData,
    personId: string,
    eventLogTrackerData?: NotificationCenterLog,
): Promise<FetchResult<EmergencyContact, string>> => {
    const apiData = transformEditEmergencyContactFormDataToEditApiParams(editEmergencyContactFormData, personId);

    try {
        const url = generateApiUrl({ endpoint: `/emergency-contacts/${apiData.id}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
            headers: {
                [NotificationCenterHeaderName.X_EVENT]: JSON.stringify(eventLogTrackerData),
            },
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EmergencyContactResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }
        const emergencyContact = transformToEmergencyContact(doc.data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: emergencyContact,
        };
    } catch (error) {
        console.error('[patchEmergencyContactApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
