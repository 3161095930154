import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../components';
import { EventLogDataItem } from '../../../../entities/EventLog/EventLog';
import trans from '../../../../helpers/trans';
import { ValkTable } from '../../../index';
import { convertEventLogDataToCategory, convertToCategoryItem } from '../../helpers';

import './PzLogDataTable.scss';

interface PzLogDataTableProps {
    dataItems: EventLogDataItem[];
    className?: string;
}

const PzLogDataTable: FC<PzLogDataTableProps> = ({ dataItems, className = '' }): ReactElement => (
    <ValkTable<EventLogDataItem>
        hasRowClick
        rows={dataItems}
        tableHeaders={[trans('compositions.pzLogDetail.subject'), trans('compositions.pzLogDetail.change')]}
        tableClassName="pz-log-data-table__table"
        cellClassName="pz-log-data-table__table-cell"
        className={className}
    >
        {(pzLogs) => pzLogs.flatMap(log => convertEventLogDataToCategory(log).map(({ key, value }) => (
            <>
                <TableRow key={`${key}-${value}`} className={`pz-log-data-table__row ${className}`}>
                    <TableCell
                        className="pz-log-data-table__key-cell"
                        contentClassName="pz-log-data-table__key-cell-content"
                        colSpan={2}
                    >
                        {key}
                    </TableCell>
                </TableRow>

                {convertToCategoryItem(value).map(({ field, change }) => (
                    <TableRow key={`${key}-${field}`} className={`pz-log-data-table__subrow ${className}`}>
                        <TableCell className="pz-log-data-table__value-cell">
                            {field}
                        </TableCell>
                        <TableCell className="pz-log-data-table__value-cell">
                            {change}
                        </TableCell>
                    </TableRow>
                ))}
            </>
        )))}
    </ValkTable>
);

export default PzLogDataTable;
