import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { LegacyEventLog } from '../../entities/EventLog/LegacyEventLog';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { EventLogActionType } from '../../types/eventLogTypes';
import { IconButton } from '../index';
import { EntityEventChange, EntityEventData } from './subcomponents';

import './EntityEvent.scss';

interface EntityEventProps {
    log: LegacyEventLog;
    className?: string;
}

const EntityEvent: FC<EntityEventProps> = ({ log, className = '' }): ReactElement => {
    const {
        action,
        changes,
        createdAt,
        createdBy,
        data,
        message,
    } = log;

    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const isExpandable = (action === EventLogActionType.created && !!data.length) || (action === EventLogActionType.updated && !!changes.length);

    const wrapperClassName = classNames('entity-event', {
        'entity-event--is-expanded': isExpanded,
    }, className);

    return (
        <div className={wrapperClassName}>
            <div className="entity-event__circle" />

            <div className="entity-event__changes">
                <h2 className="entity-event__title">{message}</h2>

                {isExpanded && (
                    <table className="entity-event__table">
                        <tbody>
                            {action === EventLogActionType.updated ? (
                                changes.map(change => (
                                    <EntityEventChange
                                        key={change.label}
                                        change={change}
                                    />
                                ))
                            ) : (
                                data.map(item => (
                                    <EntityEventData
                                        key={item.label}
                                        item={item}
                                    />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>

            <div className="entity-event__meta-and-button-wrapper">
                {`${formatDate(createdAt, DateFormat.timeOnlyLabel)} door `}
                {createdBy}

                {isExpandable && (
                    <IconButton
                        icon="chevron-down"
                        iconPos="right"
                        text={trans('common.showMore')}
                        onClick={toggleIsExpanded}
                        iconClassName="entity-event__expand-icon"
                    />
                )}
            </div>
        </div>
    );
};

export default EntityEvent;
