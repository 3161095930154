import UpToAndIncludingDate from '../helpers/date/UpToAndIncludingDate';
import { CommentResource, CommentViewModel } from './Comment';
import { LeaveOfAbsenceHours, LeaveOfAbsenceHoursResource, LeaveOfAbsenceHoursViewModel } from './LeaveOfAbsenceHours';
import { LoketBalancesViewModel } from './LoketLeaveBalance';
import { Resource } from './Resource';
import { UserResource, UserViewModel } from './User';

export enum LeaveOfAbsenceStatus {
    open = 'open',
    approved = 'approved',
    denied = 'denied',
}

export interface LeaveOfAbsenceResource extends Resource {
    type: 'leaveOfAbsences';
    status: 'open' | 'approved' | 'denied';
    reason: string;
    declineReason: string;
    start: string;
    end: string | Date;
    createdAt: string;
    updatedAt: string;
    user: UserResource;
    comments?: CommentResource[];
    leaveOfAbsenceHours?: LeaveOfAbsenceHoursResource[];
}

export interface LeaveOfAbsenceViewModel {
    type: 'leaveOfAbsences';
    id: string;
    status: LeaveOfAbsenceStatus;
    reason: string;
    declineReason: string;
    start: Date;
    end: UpToAndIncludingDate;
    user?: UserViewModel;
    comments: CommentViewModel[];
    leaveOfAbsenceHours: LeaveOfAbsenceHoursViewModel[];
}

export interface LeaveOfAbsence extends Resource {
    type: 'leaveOfAbsences';
    status: 'open' | 'approved' | 'denied';
    reason: string;
    declineReason: string;
    reviewedByUser?: string;
    start: Date;
    end: Date;
    user: UserResource;
    comments: CommentResource[];
    loketBalances?: LoketBalancesViewModel | null;
    createdAt: string;
    updatedAt: string;
    leaveOfAbsenceHours?: LeaveOfAbsenceHours[];
}

export interface LeaveOfAbsenceFormData {
    start: string;
    end: string;
    reason: string;
}

export enum LeaveOfAbsenceEditType {
    delete = 'delete',
    edit = 'edit',
    accept = 'accept',
    deny = 'deny',
}
