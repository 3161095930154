import { FC, ReactElement } from 'react';

import { TableRow } from '../../../../components';
import { LegacyEventLogChange } from '../../../../entities/EventLog/LegacyEventLog';
import trans from '../../../../helpers/trans';

import './EntityEventChange.scss';

interface EntityEventChangeProps {
    change: LegacyEventLogChange;
    className?: string;
}

const EntityEventChange: FC<EntityEventChangeProps> = ({ change, className = '' }): ReactElement => (
    <TableRow className={`entity-event-change ${className}`}>
        <td className="entity-event-change__label">
            {`${change.label}:`}
        </td>

        <td className="entity-event-change__from">
            <div className="entity-event-change__label">
                {`${trans('compositions.entityEvent.oldValue')}:`}
            </div>

            {change.from}
        </td>

        <td className="entity-event-change__to">
            <div className="entity-event-change__label">
                {`${trans('compositions.entityEvent.newValue')}:`}
            </div>

            {change.to}
        </td>
    </TableRow>
);

export default EntityEventChange;
