import { PZRoutes } from '../../../pages/PZ/PZRoutes';
import trans from '../../helpers/trans';
import { SubNavRoute } from '../routes';

export type PzNavigationRouteKey =
    'documents' |
    'complete-periods' |
    'knowledge-base' |
    'satisfaction' |
    'synchronizations' |
    'pz-logs';

const pzNavigationRoutes: SubNavRoute[] = [
    {
        key: 'complete-periods',
        path: PZRoutes.completePeriods(),
        label: trans('routes.pz.completePeriods.label'),
        permissions: ['view-all-payroll-periods', 'edit-all-payroll-periods'],
    },
    {
        key: 'satisfaction',
        path: PZRoutes.satisfaction(),
        label: trans('routes.pz.satisfaction.label'),
        permissions: ['edit-all-settings'],
        settings: ['TIMER_ENABLE_FEEDBACK'],
    },
    {
        key: 'documents',
        path: PZRoutes.documents(),
        label: trans('routes.pz.documents.label'),
        permissions: ['view-all-files', 'edit-all-files'],
    },
    {
        key: 'knowledge-base',
        path: PZRoutes.knowledgeBase(),
        label: trans('routes.pz.knowledgeBase.label'),
        permissions: ['view-all-faq'],
    },
    {
        key: 'synchronizations',
        path: PZRoutes.synchronizations(),
        label: trans('routes.pz.synchronizations.label'),
        permissions: ['manage-loket-data'],
    },
    {
        key: 'pz-logs',
        path: PZRoutes.logs(),
        label: trans('routes.pz.logs.label'),
        permissions: ['action-view-event-logs', 'action-view-event-log-categories'],
    },
];

export default pzNavigationRoutes;
